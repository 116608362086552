<template>
  <div>
    <navbar></navbar>
    <b-progress
      :value="loading"
      variant="danger"
      :animated="animate"
      class="mt-3"
    ></b-progress>

    <!--------------------------------- STEP 1 -------------------------------------------------->

    <form @submit.prevent="step++" v-show="step == 0">
      <div class="ligne">
        <h4>Demandeur</h4>
        <div class="d-flex w-50">
          <b-form-radio v-model="form1.sexeDemandeur" name="genre1" value="Mr"
            >Mr</b-form-radio
          >
          <b-form-radio v-model="form1.sexeDemandeur" name="genre1" value="Mme"
            >Mme</b-form-radio
          >
        </div>
        <b-form-group label="Nom">
          <b-form-input
            v-model="form1.nomPrenomDemandeur"
            placeholder="Nom et Prénom"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="ligne">
        <h4>Codemandeur</h4>
        <div class="d-flex w-50">
          <b-form-radio
            class="mr-4"
            v-model="form1.sexeCodemandeur"
            name="genre2"
            value="Mr"
            >Mr</b-form-radio
          >
          <b-form-radio
            v-model="form1.sexeCodemandeur"
            name="genre2"
            value="Mme"
            >Mme</b-form-radio
          >
        </div>
        <b-form-group label="Nom">
          <b-form-input
            v-model="form1.nomPrenomCodemandeur"
            placeholder="Nom et Prénom"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="ligne wrap">
        <b-form-group label="Adresse">
          <b-form-input
            v-model="form1.adresse"
            placeholder="Adresse"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Code Postal">
          <b-form-input
            v-model="form1.codePostal"
            placeholder="code Postal"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Ville">
          <b-form-input v-model="form1.ville" placeholder="Vile"></b-form-input>
        </b-form-group>
      </div>
      <div class="ligne">
        <b-form-group label="Nombre de personne dans le foyer">
          <b-form-input
            type="number"
            v-model="form1.NbrPersonneFoyer"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Revenu fiscal de référence">
          <b-form-input
            v-model="form1.RevenueFiscaleRef"
            placeholder="Revenu fiscal"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" to="/audit">Annuler</b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">Suivant</div>
        </b-button>
      </div>
    </form>

    <!--------------------------------- STEP 2 -------------------------------------------------->

    <form @submit.prevent="step++" v-show="step == 1">
      <h2>Construction</h2>
      <div class="ligne">
        <div class="flex_one">
          <b-form-radio
            v-model="form2.maisonType"
            name="typeMaison"
            value="Maison individuelle"
            >Maison individuelle</b-form-radio
          >
          <b-form-radio
            v-model="form2.maisonType"
            name="typeMaison"
            value="Appartement"
            >Appartement</b-form-radio
          >
          <b-form-radio
            v-model="form2.maisonType"
            name="typeMaison"
            value="Immeuble collectif"
            >Immeuble collectif</b-form-radio
          >
        </div>
      </div>

      <div class="ligne">
        <b-form-group label="Année de construction">
          <b-form-input v-model="form2.anneeConstruction"></b-form-input>
        </b-form-group>
        <b-form-group label="Surface habitable">
          <b-form-input
            v-model="form2.surfaceHabitableConstruction"
            placeholder="en M²"
          ></b-form-input>
        </b-form-group>
      </div>
      <h2>Niveaux</h2>
      <div class="ligne">
        <h4>Vide sanitaire</h4>
        <div class="flex_one">
          <b-form-radio
            v-model="form2.NiveauxConstruction"
            name="niveau"
            value="Sous-sol"
            >Sous-sol</b-form-radio
          >
          <b-form-radio
            v-model="form2.NiveauxConstruction"
            name="niveau"
            value="RDC"
            >RDC</b-form-radio
          >
          <b-form-radio
            v-model="form2.NiveauxConstruction"
            name="niveau"
            value="1er étage"
            >1er étage</b-form-radio
          >
          <b-form-radio
            v-model="form2.NiveauxConstruction"
            name="niveau"
            value="2ème étage"
            >2ème étage
          </b-form-radio>
        </div>
      </div>
      <div class="ligne">
        <h4>Comble aménagé</h4>
        <div class="d-flex w-40">
          <b-form-radio
            v-model="form2.NiveauxConstruction"
            name="niveau"
            value="Comble perdu"
            >Comble perdu</b-form-radio
          >
        </div>
      </div>
      <h2>Parois extérieures</h2>
      <div class="ligne wrap">
        <h4>Type</h4>
        <div class="flex_one">
          <b-form-radio
            v-model="form2.typeParoisExterieureConstruction"
            name="parois"
            value="Parpaing"
            >Parpaing</b-form-radio
          >
          <b-form-radio
            v-model="form2.typeParoisExterieureConstruction"
            name="parois"
            value="Brique"
            >Brique</b-form-radio
          >
          <b-form-radio
            v-model="form2.typeParoisExterieureConstruction"
            name="parois"
            value="Pierre"
            >Pierre</b-form-radio
          >
          <b-form-radio
            v-model="form2.typeParoisExterieureConstruction"
            name="parois"
            value="Béton"
            >Béton
          </b-form-radio>
          <b-form-radio
            v-model="form2.typeParoisExterieureConstruction"
            name="parois"
            value="Autre"
            >Autre
          </b-form-radio>
        </div>
        <b-form-group label="Epaisseur">
          <b-form-input
            v-model="form2.epaisseurParoisExterieureConstruction"
            placeholder="en cm"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Matériau</th>
              <th scope="col">Isolation existante</th>
              <th scope="col">Épaisseur</th>
              <th scope="col">Année travaux</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Murs extérieurs</th>
              <td>
                <b-form-select
                  v-model="form2.mursExtMateriau"
                  :options="option1"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="form2.mursExtIsolationExis"
                  :options="optionBool"
                ></b-form-select>
              </td>
              <td>
                <b-form-input v-model="form2.mursExtEpaisseur"></b-form-input>
              </td>
              <td>
                <b-form-input v-model="form2.mursExtAnneeTrav"></b-form-input>
              </td>
            </tr>
            <tr>
              <th scope="row">Plancher bas</th>
              <td>
                <b-form-select
                  v-model="form2.plancherBasMateriau"
                  :options="option1"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="form2.plancherBasIsolationExis"
                  :options="optionBool"
                ></b-form-select>
              </td>
              <td>
                <b-form-input
                  v-model="form2.plancherBasEpaisseur"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  v-model="form2.plancherBasAnneeTrav"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <th scope="row">Plancher intermédiaire</th>
              <td>
                <b-form-select
                  v-model="form2.plancherInterMateriau"
                  :options="option1"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="form2.plancherInterIsolationExis"
                  :options="optionBool"
                ></b-form-select>
              </td>
              <td>
                <b-form-input
                  v-model="form2.plancherInterEpaisseur"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  v-model="form2.plancherInterAnneeTrav"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <th scope="row">Plafond</th>
              <td>
                <b-form-select
                  v-model="form2.plafondMateriau"
                  :options="option1"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="form2.plafondIsolationExis"
                  :options="optionBool"
                ></b-form-select>
              </td>
              <td>
                <b-form-input v-model="form2.plafondEpaisseur"></b-form-input>
              </td>
              <td>
                <b-form-input v-model="form2.plafondAnneeTrav"></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" @click="step--"> Precedant </b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">Suivant</div>
        </b-button>
      </div>
    </form>

    <!--------------------------------- STEP 3 -------------------------------------------------->

    <form @submit.prevent="step++" v-show="step == 2">
      <div class="ligne">
        <h4>Type de système</h4>
        <div class="sub">
          <b-form-radio
            v-model="form3.typeSystemChaffage"
            name="systeme"
            value="Électrique"
            >Électrique</b-form-radio
          >
          <b-form-radio
            v-model="form3.typeSystemChaffage"
            name="systeme"
            value="Thermodynamique"
            >Thermodynamique</b-form-radio
          >
          <b-form-radio
            v-model="form3.typeSystemChaffage"
            name="systeme"
            value="Gaz"
            >Gaz</b-form-radio
          >
          <b-form-radio
            v-model="form3.typeSystemChaffage"
            name="systeme"
            value="Fioul"
            >Fioul
          </b-form-radio>
          <b-form-radio
            v-model="form3.typeSystemChaffage"
            name="systeme"
            value="Bois"
            >Bois
          </b-form-radio>
          <b-form-radio
            v-model="form3.typeSystemChaffage"
            name="systeme"
            value="Autre énergie"
            >Autre énergie
          </b-form-radio>
          <b-form-radio
            v-model="form3.typeSystemChaffage"
            name="systeme"
            value="Génération électrique et réseau"
            >Génération électrique et réseau
          </b-form-radio>
          <b-form-radio
            v-model="form3.typeSystemChaffage"
            name="systeme"
            value="Réseau de chaleur"
            >Réseau de chaleur
          </b-form-radio>
        </div>
      </div>
      <div class="ligne">
        <h4>Type d’émetteur</h4>
        <div class="sub">
          <b-form-radio
            v-model="form3.typeEmetteurChaffage"
            name="emetteur"
            value="Radiateur avec VT"
            >Radiateur avec vanne thermostatique</b-form-radio
          >
          <b-form-radio
            v-model="form3.typeEmetteurChaffage"
            name="emetteur"
            value="sans VT"
            >sans VT</b-form-radio
          >
          <b-form-radio
            v-model="form3.typeEmetteurChaffage"
            name="emetteur"
            value="Plancher chauffant"
            >Plancher chauffant</b-form-radio
          >
          <b-form-radio
            v-model="form3.typeEmetteurChaffage"
            name="emetteur"
            value="Plafond chauffant"
            >Plafond chauffant
          </b-form-radio>
          <b-form-radio
            v-model="form3.typeEmetteurChaffage"
            name="emetteur"
            value="Radiateur gaz"
            >Radiateur gaz
          </b-form-radio>
          <b-form-radio
            v-model="form3.typeEmetteurChaffage"
            name="emetteur"
            value="Air soufflé"
            >Air soufflé
          </b-form-radio>
          <b-form-radio
            v-model="form3.typeEmetteurChaffage"
            name="emetteur"
            value="Ventilo-convecteur"
            >Ventilo-convecteur
          </b-form-radio>
          <b-form-radio
            v-model="form3.typeEmetteurChaffage"
            name="emetteur"
            value="Poêle ancien"
            >Poêle ancien
          </b-form-radio>
        </div>
      </div>
      <div class="ligne">
        <h4>Type de générateur</h4>
        <div class="sub">
          <b-form-radio
            v-model="form3.typeGenerateurChaffage"
            name="generateur"
            value="Poêle au GPL"
            >Poêle au GPL</b-form-radio
          >
          <b-form-radio
            v-model="form3.typeGenerateurChaffage"
            name="generateur"
            value="Radiateur gaz à ventouse"
            >Radiateur gaz à ventouse</b-form-radio
          >
          <b-form-radio
            v-model="form3.typeGenerateurChaffage"
            name="generateur"
            value="Radiateur gaz sur conduits fumées"
            >Radiateur gaz sur conduits fumées</b-form-radio
          >
          <b-form-radio
            v-model="form3.typeGenerateurChaffage"
            name="generateur"
            value="Chaudière à gaz industrielle"
            >Chaudière à gaz industrielle
          </b-form-radio>
          <b-form-radio
            v-model="form3.typeGenerateurChaffage"
            name="generateur"
            value="Chaudière gaz instantanée"
            >Chaudière gaz instantanée
          </b-form-radio>
          <b-form-radio
            v-model="form3.typeGenerateurChaffage"
            name="generateur"
            value="Chaudière gaz basse température"
            >Chaudière gaz basse température
          </b-form-radio>
          <b-form-radio
            v-model="form3.typeGenerateurChaffage"
            name="generateur"
            value="Chaudière gaz à condensation"
            >Chaudière gaz à condensation
          </b-form-radio>
        </div>
      </div>
      <div class="ligne">
        <b-form-group label="Puissance" class="ligne">
          <b-form-input
            v-model="form3.puissanceGenerateurChaffage"
            placeholder="en kW"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Nombre de générateur" class="ligne">
          <b-form-input v-model="form3.nombreGenerateurChaffage"></b-form-input>
        </b-form-group>
      </div>
      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" @click="step--"> Precedant </b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">Suivant</div>
        </b-button>
      </div>
    </form>

    <!--------------------------------- STEP 4 -------------------------------------------------->

    <form @submit.prevent="step++" v-show="step == 3">
      <div class="ligne">
        <h4>Emplacement</h4>
        <div class="sub">
          <b-form-radio
            v-model="form3.emplacementGenerateurChaffage"
            name="emplacement"
            value="Dans volume chauffé"
            >Dans volume chauffé</b-form-radio
          >
          <b-form-radio
            v-model="form3.emplacementGenerateurChaffage"
            name="emplacement"
            value="Hors volume chauffé"
            >Hors volume chauffé</b-form-radio
          >
        </div>
      </div>
      <div class="ligne">
        <h4>Réseau hors VH chauffé</h4>
        <div class="sub">
          <b-form-radio
            v-model="form3.VhGenerateurChaffage"
            name="réseau"
            value="true"
            >Oui</b-form-radio
          >
          <b-form-radio
            v-model="form3.VhGenerateurChaffage"
            name="réseau"
            value="false"
            >Non</b-form-radio
          >
        </div>
      </div>
      <div class="ligne">
        <h4>Présence programmateur</h4>
        <div class="sub">
          <b-form-radio
            v-model="form3.programmateurGenerateur"
            name="programmateur"
            value="true"
            >Oui</b-form-radio
          >
          <b-form-radio
            v-model="form3.programmateurGenerateur"
            name="programmateur"
            value="false"
            >Non</b-form-radio
          >
        </div>
      </div>
      <div class="ligne">
        <h4>Présence sonde</h4>
        <div class="sub">
          <b-form-radio
            v-model="form3.presenceSondeGenerateur"
            name="sonde"
            value="true"
            >Oui</b-form-radio
          >
          <b-form-radio
            v-model="form3.presenceSondeGenerateur"
            name="sonde"
            value="false"
            >Non</b-form-radio
          >
        </div>
      </div>
      <div class="ligne">
        <h4>Appareil de chauffage d’appoint</h4>
        <div class="sub">
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Aucun"
            >Aucun</b-form-radio
          >
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Insert bois"
            >Insert bois</b-form-radio
          >
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Cheminée ouverte avec trappe"
            >Cheminée ouverte avec trappe</b-form-radio
          >
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Cheminée ouverte sans trappe"
            >Cheminée ouverte sans trappe
          </b-form-radio>
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Poêle buche ancien"
            >Poêle buche ancien
          </b-form-radio>
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Poêle buche performant"
            >Poêle buche performant
          </b-form-radio>
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Poêle buche du masse"
            >Poêle buche du masse
          </b-form-radio>
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Flamme verte"
            >Flamme verte</b-form-radio
          >
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Poêle à granulé"
            >Poêle à granulé
          </b-form-radio>
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Poêle à granulé flamme verte"
            >Poêle à granulé flamme verte
          </b-form-radio>
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Poêle à charbon"
            >Poêle à charbon
          </b-form-radio>
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Poêle fioul"
            >Poêle fioul
          </b-form-radio>
          <b-form-radio
            v-model="form3.appareilChauffageAppoint"
            name="appoint"
            value="Poêle GPL"
            >Poêle GPL
          </b-form-radio>
        </div>
      </div>

      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" @click="step--"> Precedant </b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">Suivant</div>
        </b-button>
      </div>
    </form>

    <!--------------------------------- STEP 5 -------------------------------------------------->

    <form @submit.prevent="step++" v-show="step == 4">
      <div class="ligne">
        <h4>ECS électrique</h4>
        <div class="sub">
          <b-form-radio
            v-model="form4.EauChaudeSanitaireECS"
            name="ECS"
            value="Pas d’ECS ou négligeable"
            >Pas d’ECS ou négligeable</b-form-radio
          >
          <b-form-radio
            v-model="form4.EauChaudeSanitaireECS"
            name="ECS"
            value="Ballon vertical"
            >Ballon vertical</b-form-radio
          >
          <b-form-radio
            v-model="form4.EauChaudeSanitaireECS"
            name="ECS"
            value="Ballon horizontal"
            >Ballon horizontal</b-form-radio
          >
          <b-form-radio
            v-model="form4.EauChaudeSanitaireECS"
            name="ECS"
            value="BTD"
            >BTD</b-form-radio
          >
          <b-form-radio
            v-model="form4.EauChaudeSanitaireECS"
            name="ECS"
            value="Électrique instantané"
            >Électrique instantané
          </b-form-radio>
        </div>
      </div>
      <div class="ligne">
        <h4>Lié au chauffage</h4>
        <div class="sub">
          <b-form-radio
            v-model="form4.EauChaudeSanitaireLieeChauf"
            name="chauffage"
            value="ECS à accumulation"
            >ECS à accumulation</b-form-radio
          >
          <b-form-radio
            v-model="form4.EauChaudeSanitaireLieeChauf"
            name="chauffage"
            value="ECS instantanée"
            >ECS instantanée</b-form-radio
          >
        </div>
      </div>
      <div class="ligne">
        <h4>Indépendant au chauffage</h4>
        <div class="sub">
          <b-form-radio
            v-model="form4.EauChaudeSanitaireIndChauf"
            name="independant"
            value="Accumulateur gaz"
            >Accumulateur gaz</b-form-radio
          >
          <b-form-radio
            v-model="form4.EauChaudeSanitaireIndChauf"
            name="independant"
            value="Accumulateur à condensation"
            >Accumulateur à condensation</b-form-radio
          >
          <b-form-radio
            v-model="form4.EauChaudeSanitaireIndChauf"
            name="independant"
            value="Chauffe bain instantané avec veilleuse"
            >Chauffe bain instantané avec veilleuse</b-form-radio
          >
          <b-form-radio
            v-model="form4.EauChaudeSanitaireIndChauf"
            name="independant"
            value="sans veilleuse"
            >sans veilleuse</b-form-radio
          >
        </div>
      </div>
      <div class="ligne">
        <div class="sub w-100">
          <b-form-group label="Capacité du ballon">
            <b-form-input
              v-model="form4.EauChaudeSanitaireCapaciteBallon"
              placeholder="en Litres"
            ></b-form-input>
          </b-form-group>
          <b-form-radio
            v-model="form4.EauChaudeSanitaireCapaciteBallonVolume"
            name="volumeChauffe"
            value="Dans volume chauffé"
            >Dans volume chauffé</b-form-radio
          >
          <b-form-radio
            v-model="form4.EauChaudeSanitaireCapaciteBallonVolume"
            name="volumeChauffe"
            value="Hors volume chauffé"
            >Hors volume chauffé</b-form-radio
          >
        </div>
      </div>
      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" @click="step--"> Precedant </b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">Suivant</div>
        </b-button>
      </div>
    </form>

    <!--------------------------------- STEP 6 -------------------------------------------------->

    <form @submit.prevent="step++" v-show="step == 5">
      <div class="ligne">
        <h4>Électricité</h4>
        <div class="sub">
          <b-form-group label="Fournisseur">
            <b-form-input
              v-model="form5.energieElectriciteFournisseur"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Puissance souscrite">
            <b-form-input
              v-model="form5.energieElectricitePuissance"
              placeholder="en kVA"
            ></b-form-input>
          </b-form-group>
          <b-form-radio
            v-model="form5.energieElectriciteMonoTri"
            name="typeElectricite"
            value="Mono"
            >Mono</b-form-radio
          >
          <b-form-radio
            v-model="form5.energieElectriciteMonoTri"
            name="typeElectricite"
            value="Tri"
            >Tri</b-form-radio
          >

          <h6>Type de tarif</h6>

          <b-form-radio
            v-model="form5.energieElectriciteTypeTarif"
            name="tarifElectricite"
            value="Bleu"
            >Bleu</b-form-radio
          >
          <b-form-radio
            v-model="form5.energieElectriciteTypeTarif"
            name="tarifElectricite"
            value="Bleu option Tempo"
            >Bleu option Tempo</b-form-radio
          >
          <b-form-radio
            v-model="form5.energieElectriciteTypeTarif"
            name="tarifElectricite"
            value="Autre"
            >Autre</b-form-radio
          >
          <b-form-group label="Consommation année">
            <b-form-input
              v-model="form5.energieElectriciteTypeTarifConsommationAnnee"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Quantité de kWh">
            <b-form-input
              v-model="form5.energieElectriciteTypeTarifQteKwh"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="ligne">
        <h4>Gaz</h4>
        <div class="sub">
          <b-form-group label="Fournisseur">
            <b-form-input v-model="form5.energieGazFournisseur"></b-form-input>
          </b-form-group>
          <b-form-group label="Puissance souscrite">
            <b-form-input
              v-model="form5.energieGazPuissance"
              placeholder="en kVA"
            ></b-form-input>
          </b-form-group>
          <b-form-radio
            v-model="form5.energieGazMonoTri"
            name="typeGaz"
            value="Mono"
            >Mono</b-form-radio
          >
          <b-form-radio
            v-model="form5.energieGazMonoTri"
            name="typeGaz"
            value="Tri"
            >Tri</b-form-radio
          >

          <h6>Type de tarif</h6>

          <b-form-radio
            v-model="form5.energieGazTypeTarif"
            name="tarifGaz"
            value="Base"
            >Base</b-form-radio
          >
          <b-form-radio
            v-model="form5.energieGazTypeTarif"
            name="tarifGaz"
            value="B0"
            >B0</b-form-radio
          >

          <b-form-radio
            v-model="form5.energieGazTypeTarif"
            name="tarifGaz"
            value="B1"
            >B1</b-form-radio
          >
          <b-form-radio
            v-model="form5.energieGazTypeTarif"
            name="tarifGaz"
            value="Autre"
            >Autre</b-form-radio
          >

          <b-form-group label="Consommation année">
            <b-form-input
              v-model="form5.energieGazTypeTarifConsommationAnnee"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Quantité de kWh">
            <b-form-input
              v-model="form5.energieGazTypeTarifQteKwh"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="ligne">
        <h4>Fioul</h4>
        <div class="sub">
          <b-form-group label="Consommation année">
            <b-form-input
              v-model="form5.energieFioulConsommationAnnee"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Quantité de litres">
            <b-form-input v-model="form5.energieFioulQteLitre"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" @click="step--"> Precedant </b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">Suivant</div>
        </b-button>
      </div>
    </form>

    <!--------------------------------- STEP 7 -------------------------------------------------->

    <form @submit.prevent="step++" v-show="step == 6">
      <div class="ligne">
        <h4>Simple flux</h4>
        <div class="sub">
          <h5>Auto réglable</h5>
          <b-form-radio
            v-model="form6.VentilationSimpleFluxAuto"
            name="SimpleFluxAuto"
            value="avant 1982"
            >avant 1982
          </b-form-radio>
          <b-form-radio
            v-model="form6.VentilationSimpleFluxAuto"
            name="SimpleFluxAuto"
            value="après 1982"
            >après 1982</b-form-radio
          >
          <h5>Hydro-réglable</h5>
          <b-form-radio
            v-model="form6.VentilationSimpleFluxHydro"
            name="SimpleFluxHydro"
            value="A"
            >A
          </b-form-radio>
          <b-form-radio
            v-model="form6.VentilationSimpleFluxHydro"
            name="SimpleFluxHydro"
            value="B"
            >B</b-form-radio
          >
        </div>
      </div>
      <div class="ligne">
        <h4>Double flux</h4>
        <div class="sub">
          <b-form-radio
            v-model="form6.VentilationDoubleFlux"
            name="doubleFlux"
            value="Avec échangeur"
            >Avec échangeur
          </b-form-radio>
          <b-form-radio
            v-model="form6.VentilationDoubleFlux"
            name="doubleFlux"
            value="Sans échangeur"
            >Sans échangeur</b-form-radio
          >
        </div>
      </div>

      <div class="ligne">
        <h4>Anciens systèmes</h4>
        <div class="sub">
          <b-form-radio
            v-model="form6.VentilationAnciensFlux"
            name="ancienSysteme"
            value="Ventilation grilles hautes et basses"
            >Ventilation grilles hautes et basses
          </b-form-radio>
          <b-form-radio
            v-model="form6.VentilationAnciensFlux"
            name="ancienSysteme"
            value="Par ouverture des fenêtres"
            >Par ouverture des fenêtres</b-form-radio
          >
        </div>
      </div>

      <div class="ligne">
        <h4>Aménagement</h4>
        <div class="sub">
          <b-form-group label="Nombre de pièces principales">
            <b-form-input v-model="form6.AmenagementNbrPiece"></b-form-input>
          </b-form-group>
          <b-form-group label="Nombre de salles d’eau">
            <b-form-input v-model="form6.AmenagementNbrSalleEau"></b-form-input>
          </b-form-group>
          <b-form-group label="Nombre de salles de bain">
            <b-form-input
              v-model="form6.AmenagementNbrSalleBain"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Nombre de WC ">
            <b-form-input v-model="form6.AmenagementNbrWc"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" @click="step--"> Precedant </b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">Suivant</div>
        </b-button>
      </div>
    </form>

    <!--------------------------------- STEP 8 -------------------------------------------------->

    <form @submit.prevent="step++" v-show="step == 7">
      <h2>PLANS DE MASSE</h2>
      <div class="ligne">
        <h4>Niveau 1</h4>
        <div class="sub">
          <b-form-group label="Plan de masse">
            <b-form-input v-model="form7.planMasseNiveauUn"></b-form-input>
          </b-form-group>
          <b-form-group label="Nom du client">
            <b-form-input
              v-model="form7.planMasseNiveauUnNomLien"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Hauteur sous plafond">
            <b-form-input
              v-model="form7.planMasseNiveauUnHauteurSousPlafond"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Surface du niveau (M²)">
            <b-form-input
              v-model="form7.planMasseNiveauUnSurfaceNiveau"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="ligne">
        <h4>Niveau 2</h4>
        <div class="sub">
          <b-form-group label="Plan de masse">
            <b-form-input v-model="form7.planMasseNiveauDeux"></b-form-input>
          </b-form-group>
          <b-form-group label="Hauteur sous plafond">
            <b-form-input
              v-model="form7.planMasseNiveauDeuxHauteurSousPlafond"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Surface du niveau (M²)">
            <b-form-input
              v-model="form7.planMasseNiveauDeuxSurfaceNiveau"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="ligne">
        <h4>Niveau 3</h4>
        <div class="sub">
          <b-form-group label="Plan de masse">
            <b-form-input v-model="form7.planMasseNiveauTroi"></b-form-input>
          </b-form-group>
          <b-form-group label="Nom du client">
            <b-form-input
              v-model="form7.planMasseNiveauTroiNomLien"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Hauteur sous plafond">
            <b-form-input
              v-model="form7.planMasseNiveauTroiHauteurSousPlafond"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Surface du niveau (M²)">
            <b-form-input
              v-model="form7.planMasseNiveauTroiSurfaceNiveau"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="ligne">
        <h4>Niveau 4</h4>
        <div class="sub w-80">
          <b-form-group label="Plan de masse">
            <b-form-input v-model="form7.planMasseNiveauQuatre"></b-form-input>
          </b-form-group>
          <b-form-group label="Hauteur sous plafond">
            <b-form-input
              v-model="form7.planMasseNiveauQuatreHauteurSousPlafond"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Surface du niveau (M²)">
            <b-form-input
              v-model="form7.planMasseNiveauQuatreSurfaceNiveau"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Surface des rampants avec les ouvertures">
            <b-form-input
              v-model="form7.planMasseNiveauQuatreSROuverture"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Schéma des combles avec les côtes">
            <b-form-input
              v-model="form7.planMasseNiveauQuatreSCCotes"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" @click="step--"> Precedant </b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">Suivant</div>
        </b-button>
      </div>
    </form>

    <!--------------------------------- STEP 9 -------------------------------------------------->
    <form @submit.prevent="step++" v-show="step == 8">
      <h2>PLANS DES FACADES</h2>
      <div class="ligne">
        <h4>Niveau 1</h4>
        <div class="sub">
          <b-form-group label="Orientation façade 1">
            <b-form-input
              v-model="form8.PlanFacadeUnOrientation"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Nom du Client">
            <b-form-input v-model="form8.PlanFacadeUnNomClient"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <table class="table table-responsive-sm table-striped">
        <tbody>
          <tr>
            <th scope="col">Surface de la façade</th>
            <th scope="col">
              <b-form-input
                v-model="form8.PlanFacadeUnSurFacade"
              ></b-form-input>
            </th>
          </tr>
          <tr>
            <th scope="row">Surface des ouvertures</th>
            <td>
              <b-form-input
                v-model="form8.PlanFacadeUnSurOuverture"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <th scope="row">Surface façade sans les ouvertures</th>
            <td>
              <b-form-input
                v-model="form8.PlanFacadeUnSurFacadeSOuverture"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <th scope="row">Surface façade à isoler</th>
            <td>
              <b-form-input v-model="form8.PlanFacadeUnIsoler"></b-form-input>
            </td>
          </tr>
        </tbody>
      </table>

      <b-row>
        <b-col sm="2">
          <label for="textarea-small">Description</label>
        </b-col>
        <b-col sm="10">
          <b-form-textarea
            id="textarea-small"
            v-model="form8.descriptionFacadeUn"
            size="lg"
            rows="4"
          ></b-form-textarea>
        </b-col>
      </b-row>

      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Désignat°</th>
              <th scope="col">Hauteur</th>
              <th scope="col">Largeur</th>
              <th scope="col">Surface</th>
              <th scope="col">Matériau</th>
              <th scope="col">Volet</th>
              <th scope="col">Vitrage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(n, i) in 10" :key="i">
              <td>
                <b-form-select
                  v-model="facade1Designation[i]"
                  :options="optionDesignation"
                ></b-form-select>
              </td>
              <td>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="facade1Hauteur[i]"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="facade1Largeur[i]"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="facade1Surface[i]"
                ></b-form-input>
              </td>
              <td>
                <b-form-select
                  v-model="facade1Materiau[i]"
                  :options="optionMateriau"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="facade1Volet[i]"
                  :options="optionVolet"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="facade1Vitrage[i]"
                  :options="optionVitrage"
                ></b-form-select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div class="ligne my-4">
        <b-form-group label="Surface totale des ouvertures">
          <b-form-input v-model="form8.TotalSurfOuvertureUn"></b-form-input>
        </b-form-group>
      </div> -->

      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" @click="step--"> Precedant </b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">Suivant</div>
        </b-button>
      </div>
    </form>

    <!--------------------------------- STEP 10 -------------------------------------------------->
    <form @submit.prevent="step++" v-show="step == 9">
      <h2>PLANS DES FACADES</h2>
      <div class="ligne">
        <h4>Niveau 2</h4>
        <div class="sub">
          <b-form-group label="Orientation façade 2">
            <b-form-input
              v-model="form9.PlanFacadeDeuxOrientation"
            ></b-form-input>
          </b-form-group>
          <!-- <b-form-group label="Nom du Client">
            <b-form-input
              v-model="form9.PlanFacadeDeuxNomClient"
            ></b-form-input>
          </b-form-group> -->
        </div>
      </div>
      <table class="table table-responsive-sm table-striped">
        <tbody>
          <tr>
            <th scope="col">Surface de la façade</th>
            <td>
              <b-form-input
                v-model="form9.PlanFacadeDeuxSurFacade"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <th scope="row">Surface des ouvertures</th>
            <td>
              <b-form-input
                v-model="form9.PlanFacadeDeuxSurOuverture"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <th scope="row">Surface façade sans les ouvertures</th>
            <td>
              <b-form-input
                v-model="form9.PlanFacadeDeuxSurFacadeSOuverture"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <th scope="row">Surface façade à isoler</th>
            <td>
              <b-form-input v-model="form9.PlanFacadeDeuxIsoler"></b-form-input>
            </td>
          </tr>
        </tbody>
      </table>

      <b-row>
        <b-col sm="2">
          <label for="textarea-small">Description</label>
        </b-col>
        <b-col sm="10">
          <b-form-textarea
            id="textarea-small"
            v-model="form9.descriptionFacadeDeux"
            size="lg"
            rows="4"
          ></b-form-textarea>
        </b-col>
      </b-row>

      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Désignat°</th>
              <th scope="col">Hauteur</th>
              <th scope="col">Largeur</th>
              <th scope="col">Surface</th>
              <th scope="col">Matériau</th>
              <th scope="col">Volet</th>
              <th scope="col">Vitrage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(n, i) in 10" :key="i">
              <td>
                <b-form-select
                  v-model="facade2Designation[i]"
                  :options="optionDesignation"
                ></b-form-select>
              </td>
              <td>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="facade2Hauteur[i]"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="facade2Largeur[i]"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="facade2Surface[i]"
                ></b-form-input>
              </td>
              <td>
                <b-form-select
                  v-model="facade2Materiau[i]"
                  :options="optionMateriau"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="facade2Volet[i]"
                  :options="optionVolet"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="facade2Vitrage[i]"
                  :options="optionVitrage"
                ></b-form-select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div class="ligne my-4">
        <b-form-group label="Surface totale des ouvertures">
          <b-form-input v-model="form9.TotalSurfOuvertureDeux"></b-form-input>
        </b-form-group>
      </div> -->

      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" @click="step--"> Precedant </b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">Suivant</div>
        </b-button>
      </div>
    </form>

    <!--------------------------------- STEP 11 -------------------------------------------------->
    <form @submit.prevent="step++" v-show="step == 10">
      <h2>PLANS DES FACADES</h2>
      <div class="ligne">
        <h4>Niveau 3</h4>
        <div class="sub">
          <b-form-group label="Orientation façade 3">
            <b-form-input
              v-model="form10.PlanFacadeTroiOrientation"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Nom du Client">
            <b-form-input
              v-model="form10.PlanFacadeTroiNomClient"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <table class="table table-responsive-sm table-striped">
        <tbody>
          <tr>
            <th scope="col">Surface de la façade</th>
            <td>
              <b-form-input
                v-model="form10.PlanFacadeTroiSurFacade"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <th scope="row">Surface des ouvertures</th>
            <td>
              <b-form-input
                v-model="form10.PlanFacadeTroiSurOuverture"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <th scope="row">Surface façade sans les ouvertures</th>
            <td>
              <b-form-input
                v-model="form10.PlanFacadeTroiSurFacadeSOuverture"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <th scope="row">Surface façade à isoler</th>
            <td>
              <b-form-input
                v-model="form10.PlanFacadeTroiIsoler"
              ></b-form-input>
            </td>
          </tr>
        </tbody>
      </table>

      <b-row>
        <b-col sm="2">
          <label for="textarea-small">Description</label>
        </b-col>
        <b-col sm="10">
          <b-form-textarea
            id="textarea-small"
            v-model="form10.descriptionFacadeTroi"
            size="lg"
            rows="4"
          ></b-form-textarea>
        </b-col>
      </b-row>

      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Désignat°</th>
              <th scope="col">Hauteur</th>
              <th scope="col">Largeur</th>
              <th scope="col">Surface</th>
              <th scope="col">Matériau</th>
              <th scope="col">Volet</th>
              <th scope="col">Vitrage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(n, i) in 10" :key="i">
              <td>
                <b-form-select
                  v-model="facade3Designation[i]"
                  :options="optionDesignation"
                ></b-form-select>
              </td>
              <td>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="facade3Hauteur[i]"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="facade3Largeur[i]"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="facade3Surface[i]"
                ></b-form-input>
              </td>
              <td>
                <b-form-select
                  v-model="facade3Materiau[i]"
                  :options="optionMateriau"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="facade3Volet[i]"
                  :options="optionVolet"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="facade3Vitrage[i]"
                  :options="optionVitrage"
                ></b-form-select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div class="ligne my-4">
        <b-form-group label="Surface totale des ouvertures">
          <b-form-input v-model="form9.TotalSurfOuvertureTroi"></b-form-input>
        </b-form-group>
      </div> -->

      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" @click="step--"> Precedant </b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">Suivant</div>
        </b-button>
      </div>
    </form>

    <!--------------------------------- STEP 12 -------------------------------------------------->
    <form @submit.prevent="step++" v-show="step == 11">
      <h2>PLANS DES FACADES</h2>
      <div class="ligne">
        <h4>Niveau 4</h4>
        <div class="sub">
          <b-form-group label="Orientation façade 4">
            <b-form-input v-model="form11.PFQOrientation"></b-form-input>
          </b-form-group>
          <!-- <b-form-group label="Nom du Client">
            <b-form-input v-model="form11.PFQNomClient"></b-form-input>
          </b-form-group> -->
        </div>
      </div>

      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="col">Surface de la façade</th>
            <td>
              <b-form-input v-model="form11.PFQSurFacade"></b-form-input>
            </td>
          </tr>
          <tr>
            <th scope="row">Surface des ouvertures</th>
            <td>
              <b-form-input v-model="form11.PFQSurOuverture"></b-form-input>
            </td>
          </tr>
          <tr>
            <th scope="row">Surface façade sans les ouvertures</th>
            <td>
              <b-form-input
                v-model="form11.PFQSurFacadeSOuverture"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <th scope="row">Surface façade à isoler</th>
            <td>
              <b-form-input v-model="form11.PFQIsoler"></b-form-input>
            </td>
          </tr>
        </tbody>
      </table>

      <b-row>
        <b-col sm="2">
          <label for="textarea-small">Description</label>
        </b-col>
        <b-col sm="10">
          <b-form-textarea
            id="textarea-small"
            v-model="form11.descriptionFacadeQuatre"
            size="lg"
            rows="4"
          ></b-form-textarea>
        </b-col>
      </b-row>

      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Désignat°</th>
              <th scope="col">Hauteur</th>
              <th scope="col">Largeur</th>
              <th scope="col">Surface</th>
              <th scope="col">Matériau</th>
              <th scope="col">Volet</th>
              <th scope="col">Vitrage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(n, i) in 10" :key="i">
              <td>
                <b-form-select
                  v-model="facade4Designation[i]"
                  :options="optionDesignation"
                ></b-form-select>
              </td>
              <td>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="facade4Hauteur[i]"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="facade4Largeur[i]"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="facade4Surface[i]"
                ></b-form-input>
              </td>
              <td>
                <b-form-select
                  v-model="facade4Materiau[i]"
                  :options="optionMateriau"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="facade4Volet[i]"
                  :options="optionVolet"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="facade4Vitrage[i]"
                  :options="optionVitrage"
                ></b-form-select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div class="ligne my-4">
        <b-form-group label="Surface totale des ouvertures">
          <b-form-input v-model="form9.TotalSurfOuvertureQ"></b-form-input>
        </b-form-group>
      </div> -->

      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" @click="step--"> Precedant </b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">Suivant</div>
        </b-button>
      </div>
    </form>

    <!--------------------------------- STEP 13 -------------------------------------------------->

    <form @submit.prevent="valider" v-show="step == 12">
      <h4 class="w-100">1. ISOLATION</h4>
      <b-container fluid>
        <b-row>
          <b-col sm="2">
            <label for="textarea-small">a. RAMPANTS :</label>
          </b-col>
          <b-col sm="10">
            <b-form-textarea
              id="textarea-small"
              size="sm"
              v-model="form12.rampantsIsolation"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <label for="textarea-small">b. COMBLE :</label>
          </b-col>
          <b-col sm="10">
            <b-form-textarea
              id="textarea-small"
              size="sm"
              v-model="form12.CombleIsolation"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <label for="textarea-small">c. MURS EXTERIEURS</label>
          </b-col>
          <b-col sm="10">
            <b-form-textarea
              id="textarea-small"
              size="sm"
              v-model="form12.MurExtIsolation"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <label for="textarea-small">d. MURS SUR LOCAL NON CHAUFFÉ</label>
          </b-col>
          <b-col sm="10">
            <b-form-textarea
              id="textarea-small"
              size="sm"
              v-model="form12.MurLocalNonChauffeIsolation"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <label for="textarea-small">e. PLANCHER BAS</label>
          </b-col>
          <b-col sm="10">
            <b-form-textarea
              id="textarea-small"
              size="sm"
              v-model="form12.PlancherBasIsolation"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </b-container>
      <h4 class="w-100">2. CHAUFFAGE ET ECS</h4>
      <b-form-textarea rows="4" v-model="form12.ChauffageEcs"></b-form-textarea>
      <h4 class="w-100">3. AUTRES INFORMATIONS UTILES POUR LE BE</h4>
      <b-form-textarea
        rows="4"
        v-model="form12.InfotmationBE"
      ></b-form-textarea>
      <div class="actionModel">
        <div class="messageError"></div>
        <b-button variant="primary" @click="step--"> Precedant </b-button>
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">
            <div v-if="getPdfLoading" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
            <div v-else>Terminer</div>
          </div>
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import navbar from '@/components/navbar.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    navbar,
  },
  data() {
    return {
      step: 0,
      totalStep: 12,
      animate: true,
      option1: [
        { value: 'béton', text: 'Béton' },
        { value: 'bois', text: 'Bois' },
        { value: 'zinc', text: 'Zinc' },
        {
          value: 'comble aménagé avec tuiles',
          text: 'Comble aménagé avec tuiles',
        },
        { value: 'ardoise', text: 'Ardoise' },
        { value: 'autre', text: 'Autre' },
      ],
      optionBool: [
        { value: 'Oui', text: 'Oui' },
        { value: 'Non', text: 'Non' },
      ],
      optionDesignation: [
        { value: 'Porte', text: 'Porte' },
        { value: 'Fenêtre', text: 'Fenêtre' },
        { value: 'Porte Fenêtre', text: 'Porte Fenêtre' },
        { value: 'Baie Vitrée', text: 'Baie Vitrée' },
      ],
      optionMateriau: [
        { value: 'Bois', text: 'Bois' },
        { value: 'Pvc', text: 'Pvc' },
        { value: 'Aluminium', text: 'Aluminium' },
        { value: 'Acier', text: 'Acier' },
      ],
      optionVolet: [
        { value: 'Sans fermeture', text: 'Sans fermeture' },
        { value: 'Volets ajourés', text: 'Volets ajourés' },
        {
          value: 'Volets roulants aluminium',
          text: 'Volets roulants aluminium',
        },
        { value: 'Volets roulants PVC', text: 'Volets roulants PVC' },
      ],
      optionVitrage: [
        { value: 'Simple Vitrage', text: 'Simple Vitrage' },
        { value: 'Double Vitrage', text: 'Double Vitrage' },
        { value: 'Survitrage', text: 'Survitrage' },
        { value: 'Double fenêtre', text: 'Double fenêtre' },
      ],
      form1: {
        sexeDemandeur: '',
        nomPrenomDemandeur: '',
        sexeCodemandeur: '',
        nomPrenomCodemandeur: '',
        adresse: '',
        ville: '',
        codePostal: '',
        NbrPersonneFoyer: '',
        RevenueFiscaleRef: '',
      },
      form2: {
        constructeur: '',
        anneeConstruction: '',
        surfaceHabitableConstruction: '',
        NiveauxConstruction: '',
        typeParoisExterieureConstruction: '',
        epaisseurParoisExterieureConstruction: '',
        mursExtMateriau: '',
        mursExtIsolationExis: '',
        mursExtEpaisseur: '',
        mursExtAnneeTrav: '',
        plancherBasMateriau: '',
        plancherBasIsolationExis: '',
        plancherBasEpaisseur: '',
        plancherBasAnneeTrav: '',
        plancherInterMateriau: '',
        plancherInterIsolationExis: '',
        plancherInterEpaisseur: '',
        plancherInterAnneeTrav: '',
        plafondMateriau: '',
        plafondIsolationExis: '',
        plafondEpaisseur: '',
        plafondAnneeTrav: '',
      },

      form3: {
        typeSystemChaffage: '',
        typeEmetteurChaffage: '',
        typeGenerateurChaffage: '',
        puissanceGenerateurChaffage: '',
        nombreGenerateurChaffage: '',
        emplacementGenerateurChaffage: '',
        VhGenerateurChaffage: '',
        programmateurGenerateur: '',
        presenceSondeGenerateur: '',
        appareilChauffageAppoint: '',
      },

      form4: {
        EauChaudeSanitaireECS: '',
        EauChaudeSanitaireLieeChauf: '',
        EauChaudeSanitaireIndChauf: '',
        EauChaudeSanitaireCapaciteBallon: '',
        EauChaudeSanitaireCapaciteBallonVolume: '',
      },

      form5: {
        energieElectriciteFournisseur: '',
        energieElectricitePuissance: '',
        energieElectriciteMonoTri: '',
        energieElectriciteTypeTarif: '',
        energieElectriciteTypeTarifConsommationAnnee: '',
        energieElectriciteTypeTarifQteKwh: '',

        energieGazFournisseur: '',
        energieGazPuissance: '',
        energieGazMonoTri: '',
        energieGazTypeTarif: '',
        energieGazTypeTarifConsommationAnnee: '',
        energieGazTypeTarifQteKwh: '',

        energieFioulConsommationAnnee: '',
        energieFioulQteLitre: '',
      },

      form6: {
        VentilationSimpleFluxAuto: '',
        VentilationSimpleFluxHydro: '',
        VentilationDoubleFlux: '',
        VentilationAnciensFlux: '',
        AmenagementNbrPiece: '',
        AmenagementNbrSalleEau: '',
        AmenagementNbrSalleBain: '',
        AmenagementNbrWc: '',
      },

      form7: {
        planMasseNiveauUn: '',
        planMasseNiveauUnNomLien: '',
        planMasseNiveauUnHauteurSousPlafond: '',
        planMasseNiveauUnSurfaceNiveau: '',
        planMasseNiveauDeux: '',
        planMasseNiveauDeuxHauteurSousPlafond: '',
        planMasseNiveauDeuxSurfaceNiveau: '',
        planMasseNiveauTroi: '',
        planMasseNiveauTroiNomLien: '',
        planMasseNiveauTroiHauteurSousPlafond: '',
        planMasseNiveauTroiSurfaceNiveau: '',
        planMasseNiveauQuatre: '',
        planMasseNiveauQuatreHauteurSousPlafond: '',
        planMasseNiveauQuatreSurfaceNiveau: '',
        planMasseNiveauQuatreSROuverture: '',
        planMasseNiveauQuatreSCCotes: '',
      },

      form8: {
        PlanFacadeUnOrientation: '',
        PlanFacadeUnNomClient: '',
        PlanFacadeUnSurFacade: '',
        PlanFacadeUnSurOuverture: '',
        PlanFacadeUnSurFacadeSOuverture: '',
        PlanFacadeUnIsoler: '',
        descriptionFacadeUn: '',
      },

      form9: {
        PlanFacadeDeuxOrientation: '',
        // PlanFacadeDeuxNomClient: '', // To fix (back)
        PlanFacadeDeuxSurFacade: '',
        PlanFacadeDeuxSurOuverture: '',
        PlanFacadeDeuxSurFacadeSOuverture: '',
        PlanFacadeDeuxIsoler: '',
        descriptionFacadeDeux: '',
      },

      form10: {
        PlanFacadeTroiOrientation: '',
        PlanFacadeTroiNomClient: '',
        PlanFacadeTroiSurFacade: '',
        PlanFacadeTroiSurOuverture: '',
        PlanFacadeTroiSurFacadeSOuverture: '',
        PlanFacadeTroiIsoler: '',
        descriptionFacadeTroi: '',
      },

      form11: {
        PFQOrientation: '',
        // PFQNomClient: '',
        PFQSurFacade: '',
        PFQSurOuverture: '',
        PFQSurFacadeSOuverture: '',
        PFQIsoler: '',
        descriptionFacadeQuatre: '',
        facade4: [],
      },

      form12: {
        rampantsIsolation: '',
        CombleIsolation: '',
        MurExtIsolation: '',
        MurLocalNonChauffeIsolation: '',
        PlancherBasIsolation: '',
        ChauffageEcs: '',
        InfotmationBE: '',
      },

      facade1Designation: [],
      facade1Hauteur: [],
      facade1Largeur: [],
      facade1Surface: [],
      facade1Materiau: [],
      facade1Volet: [],
      facade1Vitrage: [],
      facade2Designation: [],
      facade2Hauteur: [],
      facade2Largeur: [],
      facade2Surface: [],
      facade2Materiau: [],
      facade2Volet: [],
      facade2Vitrage: [],
      facade3Designation: [],
      facade3Hauteur: [],
      facade3Largeur: [],
      facade3Surface: [],
      facade3Materiau: [],
      facade3Volet: [],
      facade3Vitrage: [],
      facade4Designation: [],
      facade4Hauteur: [],
      facade4Largeur: [],
      facade4Surface: [],
      facade4Materiau: [],
      facade4Volet: [],
      facade4Vitrage: [],
    }
  },
  methods: {
    ...mapActions(['store_pdf']),
    async valider() {
      const facade1 = []
      const facade2 = []
      const facade3 = []
      const facade4 = []
      for (let index = 0; index < 10; index++) {
        if (
          this.facade1Designation[index] ||
          this.facade1Hauteur[index] ||
          this.facade1Largeur[index] ||
          this.facade1Surface[index] ||
          this.facade1Materiau[index] ||
          this.facade1Volet[index] ||
          this.facade1Vitrage[index]
        ) {
          const facade = {}
          if (this.facade1Designation[index]) {
            facade.designation = this.facade1Designation[index]
          }
          if (this.facade1Hauteur[index]) {
            facade.hauteur = this.facade1Hauteur[index]
          }
          if (this.facade1Largeur[index]) {
            facade.largeur = this.facade1Largeur[index]
          }
          if (this.facade1Surface[index]) {
            facade.surface = this.facade1Surface[index]
          }
          if (this.facade1Materiau[index]) {
            facade.materiau = this.facade1Materiau[index]
          }
          if (this.facade1Volet[index]) {
            facade.volet = this.facade1Volet[index]
          }
          if (this.facade1Vitrage[index]) {
            facade.vitrage = this.facade1Vitrage[index]
          }
          facade1.push(facade)
        }
        if (
          this.facade2Designation[index] ||
          this.facade2Hauteur[index] ||
          this.facade2Largeur[index] ||
          this.facade2Surface[index] ||
          this.facade2Materiau[index] ||
          this.facade2Volet[index] ||
          this.facade2Vitrage[index]
        ) {
          const facade = {}
          if (this.facade2Designation[index]) {
            facade.designation = this.facade2Designation[index]
          }
          if (this.facade2Hauteur[index]) {
            facade.hauteur = this.facade2Hauteur[index]
          }
          if (this.facade2Largeur[index]) {
            facade.largeur = this.facade2Largeur[index]
          }
          if (this.facade2Surface[index]) {
            facade.surface = this.facade2Surface[index]
          }
          if (this.facade2Materiau[index]) {
            facade.materiau = this.facade2Materiau[index]
          }
          if (this.facade2Volet[index]) {
            facade.volet = this.facade2Volet[index]
          }
          if (this.facade2Vitrage[index]) {
            facade.vitrage = this.facade2Vitrage[index]
          }
          facade2.push(facade)
        }
        if (
          this.facade3Designation[index] ||
          this.facade3Hauteur[index] ||
          this.facade3Largeur[index] ||
          this.facade3Surface[index] ||
          this.facade3Materiau[index] ||
          this.facade3Volet[index] ||
          this.facade3Vitrage[index]
        ) {
          const facade = {}
          if (this.facade3Designation[index]) {
            facade.designation = this.facade3Designation[index]
          }
          if (this.facade3Hauteur[index]) {
            facade.hauteur = this.facade3Hauteur[index]
          }
          if (this.facade3Largeur[index]) {
            facade.largeur = this.facade3Largeur[index]
          }
          if (this.facade3Surface[index]) {
            facade.surface = this.facade3Surface[index]
          }
          if (this.facade3Materiau[index]) {
            facade.materiau = this.facade3Materiau[index]
          }
          if (this.facade3Volet[index]) {
            facade.volet = this.facade3Volet[index]
          }
          if (this.facade3Vitrage[index]) {
            facade.vitrage = this.facade3Vitrage[index]
          }
          facade3.push(facade)
        }
        if (
          this.facade4Designation[index] ||
          this.facade4Hauteur[index] ||
          this.facade4Largeur[index] ||
          this.facade4Surface[index] ||
          this.facade4Materiau[index] ||
          this.facade4Volet[index] ||
          this.facade4Vitrage[index]
        ) {
          const facade = {}
          if (this.facade4Designation[index]) {
            facade.designation = this.facade4Designation[index]
          }
          if (this.facade4Hauteur[index]) {
            facade.hauteur = this.facade4Hauteur[index]
          }
          if (this.facade4Largeur[index]) {
            facade.largeur = this.facade4Largeur[index]
          }
          if (this.facade4Surface[index]) {
            facade.surface = this.facade4Surface[index]
          }
          if (this.facade4Materiau[index]) {
            facade.materiau = this.facade4Materiau[index]
          }
          if (this.facade4Volet[index]) {
            facade.volet = this.facade4Volet[index]
          }
          if (this.facade4Vitrage[index]) {
            facade.vitrage = this.facade4Vitrage[index]
          }
          facade4.push(facade)
        }
      }

      var data = Object.assign(
        this.form1,
        this.form2,
        this.form3,
        this.form4,
        this.form5,
        this.form6,
        this.form7,
        this.form8,
        this.form9,
        this.form10,
        this.form11,
        this.form12,
        {
          facade1,
          facade2,
          facade3,
          facade4,
        }
      )
      await this.store_pdf(data)
    },
  },

  computed: {
    ...mapGetters(['getPdfLoading']),
    loading() {
      return (this.step / this.totalStep) * 100
    },
  },
}
</script>

<style lang="scss">
form {
  @media only screen and (max-width: 900px) {
    width: 95%;
    margin: 8px auto;
  }

  width: 80%;
  margin: 32px auto;

  h2 {
    text-align: left;
    color: #e4261b;
  }
  h4 {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    font-weight: 600;
    width: 25%;
    text-align: left;
  }
}
.progress {
  @media only screen and (max-width: 900px) {
    width: 95%;
    margin: 0;
  }
  margin: 15px auto !important;
  width: 80%;
}

.ligne {
  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
    padding: 0 10px;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align-last: left;
  margin-bottom: 24px;

  .sub {
    @media only screen and (max-width: 900px) {
      width: 100%;
      padding: 0 10px;
    }
    display: flex;
    flex-wrap: wrap;
    width: 75%;
    align-items: center;
  }
  fieldset {
    @media only screen and (max-width: 900px) {
      width: 100%;
      display: flex;
    }
    display: flex;
    align-items: center;
    text-align: left;
    width: 50%;
    padding-left: 0;
  }

  legend {
    @media only screen and (max-width: 900px) {
      width: 30%;
    }
    padding: 0;
    margin-right: 4px;
  }

  h5 {
    text-align: left;
    margin: 0;
    color: red;
    width: 100%;
  }
  h6 {
    text-align: left;
    color: red;
    font-weight: 600;
    width: 100%;
    margin: 8px 0;
  }
  .w-20 {
    width: 20%;
  }

  .w-30 {
    width: 30%;
  }

  input[type='text'] {
    width: auto;
    padding: 3px 8px;
  }

  input[type='radio'] {
    margin-right: 4px;
  }

  input[type='number'] {
    max-width: 100px;
    padding: 3px 8px;
  }
}

select {
  width: 100%;
  height: 38px;
  overflow: hidden;
  min-width: 75px;
}
.wrap {
  flex-wrap: wrap;
}

.flex_one {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
}

.row {
  margin-bottom: 15px;
}
textarea {
  margin-bottom: 15px;
}

.custom-radio,
.mr-4 {
  margin-right: 1.5rem !important;
}

.actionModel {
  display: flex;
  justify-content: space-between;
  & button,
  & a {
    width: 45%;
  }
  & .block-spinner {
    display: flex;
    justify-content: center;
  }

  & .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.col-sm-2 {
  text-align: left;
}
</style>
